<template>
 <div>
  <svg height="60" viewBox="0 0 60 60" width="60" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
   <defs>
    <linearGradient gradientUnits="objectBoundingBox" id="linear-gradientpp" x1="0.9" x2="0.5" y1="-0.154" y2="1">
     <stop offset="0" stop-color="#ff27a4"/>
     <stop offset="1" stop-color="#e00000"/>
    </linearGradient>
   </defs>
   <g id="Youtube" transform="translate(-290 -288)">
    <rect :fill="background" data-name="Rectangle 21" height="60" id="Rectangle_21" transform="translate(290 288)" width="60"/>
    <path d="M35.863,8.412a4.468,4.468,0,0,0-3.143-3.164C29.946,4.5,18.828,4.5,18.828,4.5s-11.118,0-13.891.748A4.468,4.468,0,0,0,1.793,8.412C1.05,11.2,1.05,17.025,1.05,17.025s0,5.823.743,8.614a4.4,4.4,0,0,0,3.143,3.113c2.773.748,13.891.748,13.891.748s11.118,0,13.891-.748a4.4,4.4,0,0,0,3.143-3.113c.743-2.791.743-8.614.743-8.614s0-5.823-.743-8.614Zm-20.671,13.9V11.739l9.293,5.287-9.293,5.287Z" data-name="Icon awesome-youtube" id="Icon_awesome-youtube" style="fill: url(#linear-gradientpp);" transform="translate(300.95 301.5)"/>
   </g>
  </svg>
 </div>
</template>
<script>
export default {
  props: ["color1", "color2", "background"],
};
</script>